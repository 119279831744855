import { Carousel, Image } from 'antd'
import { Text } from 'poodlefi-libs-uikit'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 30px;
  padding: 30px 10px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 1px 1px 10px #00000030;
`

const Card = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  .title {
    font-size: 16px;
    color: #000;
    text-align: center;
    font-weight: bold;

    @media screen and (min-width: 430px) {
      font-size: 20px;
    }
  }

  .content {
    height: 300px;
    width: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    P {
      color: #000;
    }

    a {
      color: #0000ff;

      &.red {
        color: #ff0000;
      }
    }
  }
`

const WhitePaperSection = () => {
  return (
    <Wrapper>
      <Carousel draggable autoplay>
        <Card>
          <p className="title">White Paper</p>
          <div className="content">
            <Image
              preview={false}
              src="/images/bg/white-paper-bg.png"
              width="100%"
              onClick={() => window?.open('https://tron.network/static/doc/white_paper_v_2_0.pdf')}
            />
          </div>
        </Card>

        <Card>
          <p className="title">What is Cryptocurrency</p>
          <div className="content">
            <p>
              Cryptocurrency (sometimes referred to as crypto) is any form of money that exists digitally or virtually and uses
              cryptography to secure transactions. Cryptocurrencies have no central issuer or regulator, but instead use a
              decentralized system to record transactions and issue new units. Cryptocurrency is a digital payment system that
              does not rely on banks to verify transactions. It is a peer-to-peer system that enables anyone, anywhere to send and
              receive payments. Rather than physical currency carried and exchanged in the real world, cryptocurrency payments
              exist purely as digitized entries in an online database that describes a specific transaction. When you transfer
              cryptocurrency funds, the transaction is recorded on a public ledger. Cryptocurrency is stored in digital wallets.
              <br />
              The first cryptocurrency was Bitcoin, which was created in 2009 and remains the most famous. Much of the interest in
              cryptocurrencies is trading for profit, with speculators driving prices skyrocketing from time to time.
            </p>
          </div>
        </Card>

        <Card>
          <p className="title">Official Information</p>
          <div className="content">
            <p style={{ marginBottom: 10 }}>
              Email address: tronas18@outlook.com
              <br />
              Telegram customer service:
              <br />
              <a href="https://t.me/TRONLIVE5" target="_blank" rel="noreferrer">
                https://t.me/TRONLIVE5Click to contact
              </a>
              <br />
              Company address: Northpoint City, 1 North Point Dr, B2-131,
            </p>

            <Image preview={false} src="/images/home/white-paper-1.png" width="100%" />
          </div>
        </Card>

        <Card>
          <p className="title">Buy Currency APP</p>
          <div className="content">
            <Text color="#000" bold>
              Buy cryptocurrency on an exchange
            </Text>

            <Text color="#000" bold>
              Binance:{' '}
              <a href="https://accounts.binance.com/register?ref=35043013" target="_blank" rel="noreferrer" className="red">
                https://www.binance.com
              </a>
            </Text>

            <Text color="#000" bold>
              Gate:{' '}
              <a href="https://www.gate.io/signup/VllMBlhY?ref_type=103" target="_blank" rel="noreferrer" className="red">
                https://www.gate.io
              </a>
            </Text>

            <Text color="#000" bold>
              Huobi:{' '}
              <a href="https://www.mexc.com/register?inviteCode=1NKvm" target="_blank" rel="noreferrer" className="red">
                https://www.mexc.com
              </a>
            </Text>

            <Text color="#000" bold>
              OKX:{' '}
              <a href="https://okx.com/join/54880304" target="_blank" rel="noreferrer" className="red">
                https://www.okx.com
              </a>
            </Text>
          </div>
        </Card>

        <Card>
          <p className="title">TRON (TRX) Wallet</p>
          <div className="content">
            <p>
              TRX (full name TRONIX) is a mainnet token based on the TRON protocol issued by the TRON Foundation, referred to as
              TRX. Unlike Ethereum and Bitcoin, which are based on PoW mining, TRON adopts the DPoS mechanism.
              <br />
              <strong>What are the functions of TRON?</strong>
              <br />
              Virtual Machines / Node election / Smart Contracts / DApp Development wallet Blockchain Explorer / Decentralized
              Exchanges
              <br />
              <strong>TRX Vision</strong>
              TRX yes TRON The basic unit of account on the blockchain, from which all other tokens derive their value TRON Value
              derived, TRX All based on TRC A natural bridge token to standard tokens. TX It runs through the entire TRON
              ecosystem, has a wide range of usage scenarios, and provides power for on-chain transactions and applications.
              <br />
              <strong>TRX Wallet</strong>
              <br />
              TRX wallet can help you manage your TRX tokens, including balance query, transfer, etc. The most important thing in
              the wallet is the wallet private key, because if you have the private key, you can control the tokens in the TX
              wallet address corresponding to the private key.
              <br />
              <strong>TRX Trading</strong>
              <br />
              You can get TRX in many ways, the easiest of which is to buy it. There are many digital currency exchanges on the
              market where you can buy TRX, but users need to choose the right exchange based on their address and payment method.
              Exchanges that support the purchase of TRX: OKEX, Binance, Huobi
              <br />
              <strong>TRON’s Bandwidth and Energy</strong>
              <br />
              Too many insignificant transactions cause network congestion, similar to Ethereum, which delays transaction
              confirmation. To avoid clogging the network, the TRON network allows each account to initiate a limited number of
              transactions for free, while a higher transaction frequency requires bandwidth points. Transactions are transmitted
              and stored in the form of byte arrays in the network. The number of bandwidth points occupied by a transaction is
              determined by the size of the byte array in this transaction. If the length of the byte array of a transaction is
              100, then the transaction needs to consume 100 bandwidth points.
              <br />
              When a smart contract is running, executing each instruction consumes a certain amount of system resources, and the
              amount of resources is measured by the value of Energy.
              <br />
              <strong>TRX Transfer</strong>
              <br />
              When transferring TRX, make sure you have enough tokens in your wallet and that your bandwidth and energy are
              sufficient to cover the cost of the transfer.
              <br />
              All transactions require bandwidth, such as transfers and smart contract calls Some transactions require energy
              consumption, such as smart contract calls (TRC-20 transfers and DApp usage) Activated accounts have 600 free
              bandwidth resources every 24 hours, which can be used for multiple free transfers of TRX and TRX-10 tokens per day
              The consumed bandwidth and energy resources will be gradually restored within 24 hours.
              <br />
              <strong>What are the core projects of TRON ecosystem?</strong>
              <br />
              BitTorrent, DLive, Steemit, BTFS, BitTorrent Speed, uTorrent, TronTV
              <br />
              <strong>TRON is born</strong>
              <br />
              TRON With the mission of promoting the decentralization of the Internet, we are committed to building infrastructure
              for the decentralized Internet. TRON The protocol is one of the world&apos;s largest blockchain-based decentralized
              application operating system protocols, providing high-throughput, high- scalability, and high-reliability
              underlying public chain support for the operation of decentralized applications on the protocol. TRON It also
              provides better compatibility for Ethereum smart contracts through an innovative pluggable smart contract platform.
              <br />
              since 2018 Year 7 moon twenty four From now on, TRON Acquired BitTorrent Inc., a San Francisco-based Internet
              technology company. BitTorrent Inc. designs distributed technology that scales efficiently, remains intelligent, and
              gives creators and consumers control over their content and data. 1.7 100 million people use A product developed by
              BitTorrent Inc. BitTorrent Inc.&apos;s protocol can transfer 40% of Internet traffic.
              <br />
              <strong>Project Features</strong>
              <br />
              High throughput: High throughput is achieved by improving TRON In TPS It has been realized and its practicality for
              daily use has surpassed Bitcoin and Ethereum. Scalability: Based on good scalability and efficient smart contracts,
              applications can be deployed in more ways in TRON. Can support a large number of users. High reliability: TRON
              Having a more reliable network structure, user tokens, intrinsic value, and a higher degree of decentralized
              consensus brings an improved reward distribution
              <br />
              <strong>TRX Basic Information</strong>
              <br />
              Release time: 2017-07-01
              <br />
              Max supply: 99,214,066,142 TRX
              <br />
              Total supply: 99,214,066,142 TRX
              <br />
              Listed exchanges: 181
              <br />
              Incentive Mechanism: DoS
            </p>
          </div>
        </Card>

        <Card>
          <p className="title">Fireblocks announces support</p>
          <div className="content">
            <p>
              On May 24, local time in Singapore, Fireblocks, the world&apos;s leading digital asset and cryptocurrency
              infrastructure platform, announced that it has officially added support for TRX and all TRC20 tokens on the TRON
              chain on its digital asset platform for key institutional businesses. More than 1,200 liquidity partners, trading
              institutions and trading parties on the Fireblocks network can now securely send, receive or host TRX and other
              TRC20 tokens such as BTT, JST, NFT, etc. through the TRON chain.
              <br />
              Regarding this cooperation, Michael Shaulov, CEO and co-founder of Fireblocks Network, said: &quot;We understand
              that users are in great need of the platform to support TRX and TRC20 tokens, and we are also very happy to provide
              these tokens to institutional users. As the TRON ecosystem continues to exist and develop, we look forward to
              providing further support.&quot; It is understood that in the early access plan announced by Fireblocks, during the
              72-hour trial operation period when customers were able to obtain TRON functions, Fireblocks&apos; transaction
              volume reached 35 million US dollars.
            </p>
          </div>
        </Card>

        <Card>
          <p className="title">How to profit</p>
          <div className="content">
            <Text color="#000">
              ✅ Official Line 1:{' '}
              <a href="https://tronlive.vip" target="_blank" rel="noreferrer" className="red">
                https://tronlive.vip
              </a>
            </Text>

            <Text color="#000">
              ✅ Official Line 2:{' '}
              <a href="https://tronlive.cc" target="_blank" rel="noreferrer" className="red">
                https://tronlive.cc
              </a>
            </Text>

            <Text color="#000">
              ✅ <strong style={{ color: '#ff0000' }}>Smart Investment</strong> and{' '}
              <strong style={{ color: '#ff0000' }}>Invitation Reward</strong> TRONLIVE uses 🎉180-day smart pledge VIP level
              planning. The daily income is 8%-37%, and the higher the level, the greater the income.
              <br />
              🎉Invest on the same day, and the income will be generated after 24 hours. You need to go to the &quot;Income&quot;
              page every day to manually claim it.
              <br />
              🎉Minimum investment 10TRX Minimum withdrawal 5TRX Withdraw once a day.
              <br />( <strong style={{ color: '#ff0000' }}>1</strong> ) Cumulative deposit of 10-1999 TRX, automatically upgraded
              to VIP1, daily withdrawal 8%
              <br />( <strong style={{ color: '#ff0000' }}>2</strong> ) Cumulative deposit of 2000-9999 TRX, automatically
              upgraded to VIP2, daily withdrawal 10%
              <br />( <strong style={{ color: '#ff0000' }}>3</strong> ) Cumulative deposit of 10,000-29,999 TRX, automatically
              upgraded to VIP3, daily withdrawal 13%
              <br />( <strong style={{ color: '#ff0000' }}>4</strong> ) Cumulative deposit of 30,000-99,999 TRX, automatically
              upgraded to VIP4, daily withdrawal 16%
              <br />( <strong style={{ color: '#ff0000' }}>5</strong> ) Cumulative deposits of 100,000-199,999 TRX will
              automatically upgrade to VIP5 and daily withdrawals will be 19%
              <br />( <strong style={{ color: '#ff0000' }}>6</strong> ) Cumulative deposit of 200,000-599,999 TRX, automatically
              upgraded to VIP6, daily withdrawal 23%
              <br />( <strong style={{ color: '#ff0000' }}>7</strong> ) Cumulative deposit of 600,000-999,999 TRX, automatically
              upgraded to VIP7, daily withdrawal 27%
              <br />✅ <strong style={{ color: '#ff0000' }}>Share Link</strong>
              <br />
              Sharing platform links: Facebook, Twitter, YouTube, Instagram, TikTok, KAO, WhatsApp groups, Telegram groups, etc.
              <br />
              Use the promotion link to invite members to register and recharge; you will receive three benefits from the
              platform: 13% for the first level, 2% for the second level, and 1% for the third level, and you can withdraw the
              income immediately!
              <br />
              Team Level 1: <strong style={{ color: '#ff0000' }}>13%</strong> (TRX)
              <br />
              Team Level 2: <strong style={{ color: '#ff0000' }}>2%</strong> (TRX)
              <br />
              Team Level 3: <strong style={{ color: '#ff0000' }}>1%</strong> (TRX)
              <br />
            </Text>
          </div>
        </Card>
      </Carousel>
    </Wrapper>
  )
}

export default WhitePaperSection
