import { Image } from 'antd'
import useGetTokenPrice from 'hooks/useGetTokenPrice'
import React from 'react'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'

const Wrapper = styled.div`
  margin-top: 30px;
  padding: 30px 10px;
  background: #fff;
  box-shadow: 1px 1px 10px #00000030;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 430px) {
    gap: 20px;
  }

  h2 {
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;

    @media screen and (min-width: 430px) {
      font-size: 32px;
    }
  }
`

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 430px) {
    gap: 20px;
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;

    .left {
      display: flex;
      align-items: center;
      gap: 8px;

      .ant-image-img {
        width: 24px;
        height: 24px;

        @media screen and (min-width: 430px) {
          width: 40px;
          height: 40px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .percent {
        padding: 8px 16px;
        border-radius: 8px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        min-width: 70px;

        &.red {
          background: #dd345b;
        }

        &.green {
          background: #03ad91;
        }

        &.grey {
          background: #ccc;
        }
      }
    }

    p {
      font-size: 12px;

      @media screen and (min-width: 430px) {
        font-size: 16px;
      }
    }
  }
`

const MarketSection = () => {
  const { data: btcToken } = useGetTokenPrice("0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c", { refreshInterval: 30000 })
  const { data: bnbToken } = useGetTokenPrice("0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", { refreshInterval: 30000 })
  const { data: dogePrice } = useGetTokenPrice("0xbA2aE424d960c26247Dd6c32edC70B295c744C43", { refreshInterval: 30000 })
  const { data: ethPrice } = useGetTokenPrice("0x2170Ed0880ac9A755fd29B2688956BD959F933F8", { refreshInterval: 30000 })
  const { data: litePrice } = useGetTokenPrice("0x4338665CBB7B2485A8855A139b75D5e34AB0DB94", { refreshInterval: 30000 })
  const { data: trxPrice } = useGetTokenPrice("0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B", { refreshInterval: 30000 })

  return (
    <Wrapper>
      <h2>Market Center</h2>

      <List>
        {[
          {
            name: 'Bitcoin',
            symbol: 'BTC',
            price: +btcToken?.priceUsd || 0,
            percent: btcToken?.priceChange?.h24 || 0,
          },
          {
            name: 'BNB',
            symbol: 'BNB',
            price: +bnbToken?.priceUsd || 0,
            percent: bnbToken?.priceChange?.h24 || 0,
          },
          {
            name: 'Dogecoin',
            symbol: 'DOG',
            price: +dogePrice?.priceUsd || 0,
            percent: dogePrice?.priceChange?.h24 || 0,
          },
          {
            name: 'Ethereum',
            symbol: 'ETH',
            price: +ethPrice?.priceUsd || 0,
            percent: ethPrice?.priceChange?.h24 || 0,
          },
          {
            name: 'Litecoin',
            symbol: 'LTC',
            price: +litePrice?.priceUsd || 0,
            percent: litePrice?.priceChange?.h24 || 0,
          },
          {
            name: 'TRON',
            symbol: 'TRX',
            price: +trxPrice?.priceUsd || 0,
            percent: trxPrice?.priceChange?.h24 || 0,
          },
        ].map((item) => (
          <div className="item" key={item.symbol}>
            <div className="left">
              <Image src={`/images/tokens/${item.symbol.toLowerCase()}.png`} preview={false} />
              <p>{item.name} / USDT</p>
            </div>

            <div className="right">
              <p>{addCommasToNumber(item.price)}</p>
              <p className={`percent ${item.percent < 0 ? 'red' : 'green'}`}>{addCommasToNumber(item.percent)} %</p>
            </div>
          </div>
        ))}
      </List>
    </Wrapper>
  )
}

export default MarketSection
