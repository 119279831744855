import BackButton from 'components/BaseButton/BackButton'
import React from 'react'
import styled from 'styled-components'
import { Tabs } from 'antd'
import AllTransaction from './AllTransaction'

const Wrapper = styled.div`
  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      flex: 1;
      margin: 0;
      justify-content: center;

      > div {
        color: rgb(102, 102, 102);
      }

      &.ant-tabs-tab-active {
        div {
          color: rgb(254, 110, 162);
        }
      }
    }
  }

  .ant-tabs-ink-bar {
    background: rgb(254, 110, 162);
  }

  .ant-table-cell {
    font-size: 12px;

    @media screen and (min-width: 430px) {
      font-size: 14px;
    }
  }
`

const TransactionPage = () => {
  return (
    <Wrapper>
      <BackButton title="Transaction" />

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="All" key="1">
          <AllTransaction type="ALL" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Claim" key="2">
          <AllTransaction type="CLAIM" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Deposit" key="3">
          <AllTransaction type="DEPOSIT" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Withdraw" key="4">
          <AllTransaction type="WITHDRAW" />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  )
}

export default TransactionPage
