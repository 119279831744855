import { Table } from 'antd'
import BaseButton from 'components/BaseButton'
import Routes from 'pages/Routes'
import { Flex } from 'poodlefi-libs-uikit'
import React from 'react'
import { useHistory } from 'react-router-dom'
import AccountSwr from 'services/swr/account'
import PackageSwr from 'services/swr/package'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .ant-table-wrapper {
    width: 100%;
  }
`
const Balances = styled.div`
  background-image: url(/images/bg/withdraw-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  padding: 16px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  p {
    color: #fff;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
  }

  .value {
    font-size: 24px;
    font-weight: 700;

    span {
      font-size: 14px;
      position: relative;
      bottom: 2px;
    }
  }
`

const InvestmentPage = () => {
  const history = useHistory()
  const { data: balances } = AccountSwr.useGetBalance()
  const { data: packageList } = PackageSwr.useGetMyPackageList()

  const columns: any = [
    {
      title: 'Level',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (name) => name,
    },
    {
      title: 'Recharge Quantity',
      dataIndex: 'min_amount',
      key: 'min_amount',
      align: 'center',
      render: (value, item) => `${addCommasToNumber(value / 1e6, 0)} - ${addCommasToNumber(item?.max_amount / 1e6, 0)}`,
    },
    {
      title: 'Daily Profit',
      dataIndex: 'daily_interest_rate',
      key: 'daily_interest_rate',
      align: 'center',
      render: (value) => `${+value / 100}%`,
    },
  ]

  return (
    <Wrapper>
      <Balances>
        <p className="title">Available Balance</p>
        <p className="value">
          {!balances ? '--' : addCommasToNumber(+balances?.TRX)} <span>TRX</span>
        </p>

        <Flex alignItems="center" justifyContent="space-between" width="100%" style={{ gap: 20 }}>
          <BaseButton background="#fff" textColor="#fe6ea2" onClick={() => history.push(Routes.recharge)} style={{ padding: 12 }}>
            Recharge Now
          </BaseButton>

          <BaseButton
            background="#fff"
            textColor="#fe6ea2"
            onClick={() => history.push(Routes.investmentDetail)}
            style={{ padding: 12 }}
          >
            Invest Now
          </BaseButton>
        </Flex>
      </Balances>

      <Table
        dataSource={packageList}
        columns={columns}
        scroll={undefined}
        loading={!packageList}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        rowKey={(item) => item.name}
      />
    </Wrapper>
  )
}

export default InvestmentPage
