export function stringify(payload: any) {
  return Object.keys(payload)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`)
    .join('&')
}

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  return number ? number.toLocaleString(undefined, options) : 0
}

export function getRootUrl(
  options = {
    withPathName: false,
  }
) {
  if (typeof window !== undefined) {
    if (options.withPathName) {
      // eslint-disable-next-line prefer-template
      return window.location.protocol + '//' + window.location.hostname + window.location.pathname
    }
      // eslint-disable-next-line prefer-template
    return window.location.protocol + '//' + window.location.hostname
  }
  return ''
}
