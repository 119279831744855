import { Form, Input, message } from 'antd'
import BaseButton from 'components/BaseButton'
import Routes from 'pages/Routes'
import { Text } from 'poodlefi-libs-uikit'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { authService } from 'services'
import styled from 'styled-components'
import Cookies from 'js-cookie'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

const FormWrapper = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media screen and (min-width: 430px) {
    width: 80%;
  }

  .ant-form {
    width: 100%;

    label {
      color: #191919;
      font-weight: bold;
      font-size: 16px;
    }

    input {
      border-radius: 16px;
      color: #ff82af;

      &:disabled {
        background: #ccc;
        opacity: 0.8;
      }

      ::placeholder {
        color: #837d7f;
      }
    }

    .ant-input-affix-wrapper {
      padding: 0 11px;
      border-radius: 16px;
      background-color: #fff1f5 !important;
      border-color: transparent;

      input {
        border: none !important;
        box-shadow: none !important;
      }
    }

    .ant-form-item-explain {
      div {
        font-size: 10px;
      }
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;

  @media screen and (min-width: 430px) {
    width: 80%;
    padding-bottom: 30px;
  }
`

const ForgotPasswordPage = () => {
  const history = useHistory()
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const refLocalStorage = JSON.parse(localStorage.getItem('ref') as string)
  const cookieRef = Cookies.get('ref')

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      setLoading(true)

      await authService.register(
        values,
        () => {
          localStorage.removeItem('ref')
          Cookies.remove('ref')
          setLoading(false)
          message.success("Register account success!")
          history.push(Routes.signIn)
        },
        (e) => {
          setLoading(false)
          message.error(e?.message || 'Register account failed!')

          if (e?.errors) {
            form.setFields(
              Object.keys(e?.errors).map(x => (
                {
                  name: x,
                  value: values[x],
                  errors: e?.errors[x],
                }
              ))
            );
          }
        }
      )
    })
  }

  useEffect(() => {
    if (!refLocalStorage && !cookieRef) return
    
    form.setFieldsValue({
      ref_code: refLocalStorage?.ref || cookieRef
    })
    
  }, [cookieRef, form, refLocalStorage])

  return (
    <Wrapper>
      <FormWrapper>
        <Form form={form} layout="vertical">
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Username is require',
              },
            ]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Password is require' },
              { min: 8, message: 'The password must be at least 8 characters.' },
            ]}
          >
            <Input.Password placeholder="Password" autoComplete="false" />
          </Form.Item>

          <Form.Item
            name="password_confirmation"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Confirm your password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Password not match'))
                },
              }),
            ]}
          >
            <Input.Password placeholder="Re-Password" autoComplete="false" />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Email is require' },
              {
                type: 'email',
                message: 'Invalid Email',
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: 'Phone number is require',
              },
            ]}
          >
            <Input placeholder="Phone number" />
          </Form.Item>

          <Form.Item
            name="ref_code"
          >
            <Input disabled={refLocalStorage || cookieRef} placeholder="Invitation code" />
          </Form.Item>
        </Form>
      </FormWrapper>

      <ButtonWrapper>
        <BaseButton loading={loading} onClick={handleSubmit}>Register</BaseButton>
        <BaseButton background="#ffecf2" textColor="#ff1964" onClick={() => history.push('/auth/sign-in')}>
          Login?
        </BaseButton>

        <Text
          textAlign="center"
          color="#fe6ea2"
          fontSize="16px"
          style={{ cursor: 'pointer', width: '100%' }}
          onClick={() => history.push('/')}
        >
          Home
        </Text>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default ForgotPasswordPage
