import React, { useState } from 'react'
import QRCode from 'react-qr-code'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { message } from 'antd'
import styled from 'styled-components'
import BackButton from 'components/BaseButton/BackButton'
import { Flex, Skeleton } from 'poodlefi-libs-uikit'
import BaseButton from 'components/BaseButton'
import { transactionService } from 'services'
import AccountSwr from 'services/swr/account'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  > svg {
    width: 160px;
    height: 160px;

    @media screen and (min-width: 430px) {
      width: 200px;
      height: 200px;
    }
  }

  .address {
    font-size: 12px;
    font-weight: bold;
  }

  .label {
    color: #333;
  }

  .red {
    color: #eb0909;
    font-size: 12px;
  }
`

const Payment = () => {
  const [loading, setLoading] = useState(false)
  const { mutate: refreshBalances } = AccountSwr.useGetBalance()
  const { data: userInfo } = AccountSwr.useGetMyProfile()

  const handleConfirm = async () => {
    setLoading(true)
    await transactionService.syncTransaction(async (res) => {
      await refreshBalances()
      setLoading(false)
      message.success(res?.message)
    }, (e) => {
      setLoading(false)
      message.error(e?.message || 'Recharge failed. Try again')
    })
  }

  return (
    <Wrapper>
      <BackButton title="Payment" />

      {userInfo ? <QRCode value={userInfo?.user_account?.account?.account_address} /> : <Skeleton width={200} height={200}/>}

      <Flex flexDirection="column" style={{ gap: 8 }}>
        <p className="label">Address</p>
        <p className="address">{userInfo?.user_account?.account?.account_address || '--'}</p>
      </Flex>

      <CopyToClipboard onCopy={() => message.success('Copied success')} text={userInfo?.user_account?.account?.account_address}>
        <BaseButton background="#ffecf2" textColor="#ff1964">
          Copy Address
        </BaseButton>
      </CopyToClipboard>

      <BaseButton onClick={handleConfirm} loading={loading}>Recharge Completed</BaseButton>

      <Flex flexDirection="column" style={{ gap: 8 }}>
        <p className="label">Description</p>
        <p className="red">
          1.If the account is not credited for a long time, please click &quot;Recharge Completed&quot; again.
        </p>
      </Flex>
    </Wrapper>
  )
}

export default Payment
