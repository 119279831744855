import axios from 'axios'
import useSWR, { SWRConfiguration } from 'swr'

const useGetTokenPrice = (tokenAddress: string, configs?: SWRConfiguration): any => {
  const fetchData = async () => axios
  .get(`https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`)
  .then((res) => res?.data?.pairs?.[0])

  return useSWR(tokenAddress ? ['useGetTokenPrice', tokenAddress] : null, fetchData, {
    revalidateOnFocus: false,
    ...configs,
  })
}

export default useGetTokenPrice
