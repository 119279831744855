import React from 'react'
import styled from 'styled-components'
import ModalWrapper from './ModalWrapper'

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`

const ModalLoadingWithdraw = ({ visible, onCancel }) => {
  return (
    <ModalWrapper visible={visible} onCancel={onCancel} title="Withdrawing" width={500}>
      <ModalBody>
        <p>Withdrawal request is being processed. This may take 1-10 minutes. Please do not close the browser.</p>
      </ModalBody>
    </ModalWrapper>
  )
}

export default ModalLoadingWithdraw
