import { Col, Image, Row } from 'antd'
import { Box } from 'poodlefi-libs-uikit'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 20px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    p {
      font-size: 13px;
      text-align: center;
      color: #2a2a2a;
      margin-top: -10px;
      font-weight: 500;

      @media screen and (min-width: 430px) {
        font-size: 18px;
      }
    }

    span {
      font-size: 11px;
      color: #ccc;

      @media screen and (min-width: 430px) {
        font-size: 14px;
      }
    }
  }
`

const AppMenuSection = () => {
  const history = useHistory()

  return (
    <Wrapper>
      <Row gutter={[0, 30]}>
        {[
          {
            title: 'Top up',
            href: '/recharge',
          },
          {
            title: 'Withdrawal',
            href: '/withdraw',
          },
          {
            title: 'Share',
            href: '/share',
          },
          {
            title: 'Team',
            href: '/referral',
          },
          {
            title: 'Download',
            href: '#',
          },
          {
            title: 'Conversion',
            href: '#',
          },
          {
            title: 'Information',
            href: '#',
          },
          {
            title: 'Transaction History',
            href: '/transactions',
          },
          {
            title: 'Online Customer Service',
            href: '#',
          },
        ].map((item, i) => (
          <Col span={8} key={item.title}>
            <Box className="content" onClick={() => history.push(item.href)}>
              <Image src={`/images/home/icons/menu-${i + 1}.png`} preview={false} />
              <p>{item.title}</p>
              {item?.href === '#' && <span>(Coming soon)</span>}
            </Box>
          </Col>
        ))}
      </Row>
    </Wrapper>
  )
}

export default AppMenuSection
