import React from 'react'
import AccountSwr from 'services/swr/account'
import styled from 'styled-components'
import QRCode from 'react-qr-code'
import { CopyIcon, Skeleton } from 'poodlefi-libs-uikit'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { message } from 'antd'
import * as qs from 'query-string'
import { getRootUrl } from 'helpers'
import BaseButton from 'components/BaseButton'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  .qr-code {
    margin: 20px 0;
    width: 160px;
    height: 160px;

    @media screen and (min-width: 430px) {
      width: 200px;
      height: 200px;
    }
  }

  h2 {
    color: #2a2a2a;
    font-weight: 700;
    text-align: center;
    font-size: 24px;

    @media screen and (min-width: 430px) {
      font-size: 32px;
    }
  }

  .sub-title {
    text-align: center;
    font-size: 14px;

    @media screen and (min-width: 430px) {
      font-size: 16px;
    }
  }

  .copy-icon {
    fill: #2a2a2a;
  }
`

const MyCode = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .value {
    font-weight: 700;
    color: #ef5079;
  }
`

const Banner = styled.div`
	padding: 12px;
	background: #fbf2ec;
	border-radius: 40px;
	display: flex;
	align-items: center;
	gap: 20px;

	img {
		width: 60px;
		flex-shrink: 0;
	}

	div {
		display: flex;
		flex-direction: column;
		gap: 4px;

		p {
			:nth-child(1) {
				font-weight: bold;
				font-size: 14px;
			}

			:nth-child(2) {
				font-weight: 500;
				font-size: 10px;
				color: #ef5079;
			}
		}
	}
`

const SharePage = () => {
  const { data: userInfo } = AccountSwr.useGetMyProfile()

  const params = qs.stringify({
    ref: userInfo?.ref_code,
  })

  const refLink = `${getRootUrl()}${window.location.port ? `:${window.location.port}` : ''}?${params}`

  const copySuccess = () => message.success('Copied success')

  return (
    <Wrapper>
      <h2>Invite Your Friends</h2>
      <p className="sub-title">Register and recharge to get generous rewards</p>

      {userInfo ? <QRCode value={refLink} className="qr-code" /> : <Skeleton width={200} height={200} />}

      <MyCode>
        <p>My Invitation Code:</p>
        <p className="value">{!userInfo ? '--' : userInfo?.ref_code}</p>
        {userInfo && (
          <CopyToClipboard onCopy={copySuccess} text={userInfo?.ref_code}>
            <CopyIcon className="copy-icon" />
          </CopyToClipboard>
        )}
      </MyCode>
      {userInfo && (
        <CopyToClipboard onCopy={copySuccess} text={refLink}>
          <BaseButton background="linear-gradient(90deg,#fe8bb8,#eb4068)">Share Now</BaseButton>
        </CopyToClipboard>
      )}

	  <Banner>
		<img src="/images/share/gift.png" alt="" />
		<div>
			<p>Share the invitation link on social platforms</p>
			<p>Establish a team to receive generous rewards</p>
		</div>
	  </Banner>
    </Wrapper>
  )
}

export default SharePage
