/* eslint-disable react/react-in-jsx-scope */
import Cookies from 'js-cookie'
import * as qs from 'query-string'
import { useLayoutEffect } from 'react'
import styled from 'styled-components'
import AppMenuSection from './components/AppMenuSection'
import BalanceSection from './components/BalanceSection'
// import EarningSection from './components/EarningSection'
import HeroSlider from './components/HeroSlider'
import InvestmentSection from './components/InvestmentSection'
import MarketSection from './components/MarketSection'
import NotificationSection from './components/NotificationSection'
import PartnerSection from './components/PartnerSection'
import WhitePaperSection from './components/WhitePaperSection'

const WrapDashboardPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .hero-image {
    border-radius: 20px;
  }
`

const DashboardPage = ({ location }) => {
  const { ref } = qs.parse(location.search)

  useLayoutEffect(() => {
    if (typeof ref === "string") {
      localStorage.setItem(
        'ref',
        JSON.stringify({
          ref,
        })
      )
      Cookies.set('ref', ref)
    }
  }, [ref])

  return (
    <WrapDashboardPage>
      <HeroSlider />
      <BalanceSection />
      <NotificationSection />
      <AppMenuSection />
      {/* <AccountSection /> */}
      <InvestmentSection />
      <WhitePaperSection />
      <MarketSection />
      {/* <EarningSection /> */}
      <PartnerSection />
    </WrapDashboardPage>
  )
}

export default DashboardPage
