import { Grid, Modal } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { ModalBaseType } from 'types'

const {useBreakpoint} = Grid

type ModalWrapperType = ModalBaseType & {
  children: React.ReactNode
  title: string
  width?: number
}

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .title {
    width: 100%;

    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: rgb(254, 110, 162);
    text-transform: uppercase;

    border-bottom: 2px solid #00000030;
    padding-bottom: 8px;
  }

  .input-amount {
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(#e9b5cc,#eb74a8);
    padding: 6px 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    ${({theme}) => theme.mediaQueries.md} {
      padding: 12px 16px;
    }

    .ant-input-number {
      flex: 1;
      background-color: transparent;
      outline: none;
      border: none;
      box-shadow: none;

      color: #EFDABC;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;

      ${({theme}) => theme.mediaQueries.md} {
        font-size: 16px;
      }

      input {
        padding: 0;
      }
    }
  }
`

const ModalWrapper = ({ visible, onCancel, children, title, width }: ModalWrapperType) => {
  const breakpoint = useBreakpoint()

  return (
    <Modal centered footer={false} width={width || (breakpoint?.md ? 380 : 320)} visible={visible} onCancel={onCancel}>
      <ModalBody>
       <p className="title">{title}</p>
        {children}
      </ModalBody>
    </Modal>
  )
}

export default ModalWrapper


