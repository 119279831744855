import fetchWrapper from 'helpers/fetch-wrapper'
import useSWR, { SWRConfiguration } from 'swr'
import SWR_KEY from './constant'

const apiBaseUrl = `/referral`

const useGetMyF1 = (configs?: SWRConfiguration) => {
  const fetchData = async () => fetchWrapper.get(`${apiBaseUrl}/get-tree`).then((res) => res?.data?.docs)

  return useSWR(SWR_KEY.GET_F1_LIST, fetchData, {
    revalidateOnFocus: false,
	...configs
  })
}

const ReferralSwr = {
	useGetMyF1
} 

export default ReferralSwr
