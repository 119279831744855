import React from 'react'
import { Col, Image, Row } from 'antd'
import BaseButton from 'components/BaseButton'
import Routes from 'pages/Routes'
import { Skeleton } from 'poodlefi-libs-uikit'
import { useHistory } from 'react-router-dom'
import ReferralSwr from 'services/swr/referral'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`

const Banner = styled.div`
  background-image: url(/images/bg/withdraw-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  padding: 16px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  p {
    color: #fff;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
  }

  .value {
    font-size: 28px;
    font-weight: 700;
  }
`

const List = styled(Row)`
	width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;

    .left {
      display: flex;
      align-items: center;
      gap: 8px;

      .ant-image-img {
        width: 20px;

        @media screen and (min-width: 430px) {
          width: 32px;
        }
      }
    }
  }
`

const F1Page = () => {
	const history = useHistory()
	const { data: f1List } = ReferralSwr.useGetMyF1()


  return (
    <Wrapper>
      <Banner>
        <p className="title">Total F1 Invited</p>
        <p className="value">{!f1List ? '--' : f1List?.children?.length}</p>
		<BaseButton background="#fff" textColor="#fe6ea2" onClick={() => history.push(Routes.share)} style={{ padding: 12 }}>
			Invited now
		</BaseButton>
      </Banner>

      {!f1List ? (
        <Skeleton width="100%" height={200} />
      ) : (
        <List gutter={[15, 15]}>
          {f1List?.children?.map((item) => (
            <Col span={12} key={item?.id}>
              <div className="item">
                <div className="left">
                  <Image src="/favicon.ico" preview={false} />
                  <p>{item.username}</p>
                </div>
              </div>
            </Col>
          ))}
        </List>
      )}
    </Wrapper>
  )
}

export default F1Page
