const SWR_KEY = {
  GET_ACCOUNT_BALANCE: 'GET_ACCOUNT_BALANCE',
  GET_MY_TRANSACTION: 'GET_MY_TRANSACTION',
  GET_ME: 'GET_ME',
  GET_PACKAGE_LIST: 'GET_PACKAGE_LIST',
  GET_PACKAGE_HISTORY: 'GET_PACKAGE_HISTORY',
  GET_F1_LIST: 'GET_F1_LIST',
}

export default SWR_KEY
