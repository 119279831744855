import React from 'react'
import AccountSwr from 'services/swr/account'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'
import { DownloadOutlined, ExportOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import Routes from 'pages/Routes'
import BaseButton from 'components/BaseButton'
import { authService } from 'services'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .header-title {
    color: #abacb9;
    text-align: center;
    font-size: 18px;
  }

  h2 {
    font-size: 30px;
    font-weight: bold;

    span {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .level {
	font-size: 20px;
  }
`

const Wallet = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;

  .item {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 16px;
    border-radius: 16px;

    color: #fff;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    &.blue {
      background: #80a7f9;
    }

    &.pink {
      background: #f25f87;
    }

    > p {
      flex: 1;
      text-align: center;
    }

    .anticon {
      flex-shrink: 0;
      font-size: 24px;
    }
  }
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    padding: 8px 16px;
    background: #f9fbfd;
    border-radius: 16px;

    p {
      color: #5a5253;
      font-size: 16px;
      font-weight: 500;
    }

    img {
      width: 40px;
      height: 40px;
    }
  }
`

const MyPage = () => {
  const history = useHistory()
  const { data: balances } = AccountSwr.useGetBalance()
  const { data: userInfo } = AccountSwr.useGetMyProfile()

  return (
    <Wrapper>
      <p className="header-title">Total Balance</p>
      <h2>
        {!balances ? '--' : addCommasToNumber(+balances?.TRX)} <span>TRX</span>
      </h2>

	  <p className='level'>Level: {userInfo ? userInfo?.user_account?.level : '--'}</p>

      <Wallet>
        <div className="item blue" onClick={() => history.push(Routes.recharge)}>
          <DownloadOutlined />
          <p>Recharge</p>
        </div>

        <div className="item pink" onClick={() => history.push(Routes.withdraw)}>
          <ExportOutlined />
          <p>Withdraw</p>
        </div>
      </Wallet>

      <Menu>
        <div className="item" onClick={() => history.push(Routes.transactions)}>
          <img src="/images/me/tele.png" alt="logo" />
          <p>Transaction History</p>
        </div>

        <div className="item" onClick={() => history.push(Routes.investmentDetail)}>
          <img src="/images/me/tele.png" alt="logo" />
          <p>Investment record</p>
        </div>
      </Menu>

      <BaseButton
        onClick={() => {
          authService.logout()
          history.push(Routes.home)
        }}
      >
        Logout
      </BaseButton>
    </Wrapper>
  )
}

export default MyPage
