import BaseButton from 'components/BaseButton'
import React from 'react'
import { useHistory } from 'react-router-dom'
import AccountSwr from 'services/swr/account'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'

const Wrapper = styled.div`
  width: 100%;
  min-height: 200px;
  padding-left: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  background: url(/images/home/balance.png) no-repeat;
  background-size: contain;
  background-position: center;

  @media screen and (min-width: 430px) {
    min-height: 300px;
    padding-left: 40px;
  }

  .label {
    color: #fe6ea2;
    font-weight: 500;

    @media screen and (min-width: 430px) {
      font-size: 24px;
    }
  }

  .content {
    font-size: 24px;
    font-weight: 700;

    @media screen and (min-width: 430px) {
      font-size: 42px;
    }

    span {
      font-size: 16px;
      position: relative;
      bottom: 2px;

      @media screen and (min-width: 430px) {
        bottom: 4px;
        font-size: 24px;
      }
    }
  }
`

const BalanceSection = () => {
  const history = useHistory()
  const { data: balances } = AccountSwr.useGetBalance()

  return (
    <Wrapper>
      <p className="label">Total Balance</p>
      <p className="content">
        {!balances ? '--' : addCommasToNumber(+balances?.TRX)} <span>TRX</span>
      </p>
      <BaseButton width={150} onClick={() => history.push('/recharge')}>
        Deposit
      </BaseButton>
    </Wrapper>
  )
}

export default BalanceSection
