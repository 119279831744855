import { createGlobalStyle } from 'styled-components'
// import bg from "../assets/images/background/bg.svg"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Monument Extended';
    src: local('Monument'), url('/fonts/Monument.otf') format('truetype');
  }

  @font-face {
    font-family: 'Grobold';
    src: url('/fonts/Grobold.ttf');
  }

  @keyframes linerShowHide {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  
  @keyframes linerShowHide0-2 {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0.2;
    }
  }
  
  @keyframes rotateAround {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
  
  @keyframes upAndDown {
    0% {
      transform: translate(var(--transformX), var(--transformY));
    }
    50% {
      transform: translate(var(--transformX), calc(var(--transformY) + 10px));
    }
    100% {
      transform: translate(var(--transformX), var(--transformY));
    }
  }
  
  @keyframes cup-top-token-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes blinking {
    0% {
      background-image: linear-gradient(to right, rgba(67, 67, 67, 0.4), rgba(0, 0, 0, 0.4), rgba(67, 67, 67, 0.4));
      transform: translate(-48%, -50%) rotate(25.82deg) skewX(0);
    }
    10% {
      background-image: linear-gradient(to right, rgba(67, 67, 67, 0), rgba(0, 0, 0, 0), rgba(67, 67, 67, 0));
    }
    30% {
      background-image: linear-gradient(to right, rgba(67, 67, 67, 0.6), rgba(0, 0, 0, 0.6), rgba(67, 67, 67, 0.6));
      transform: translate(-48%, -50%) rotate(25.82deg) skewX(-2deg);
    }
    31% {
      background-image: linear-gradient(to right, rgba(67, 67, 67, 0.7), rgba(0, 0, 0, 0.7), rgba(67, 67, 67, 0.7));
    }
    31.5% {
      transform: translate(-48%, -50%) rotate(25.82deg) skewX(89deg);
    }
    32.1% {
      transform: translate(-48%, -50%) rotate(25.82deg) skewX(0);
    }
    33% {
      transform: translate(-48%, -50%) rotate(25.82deg) skewX(2deg);
    }
    34% {
      background-image: linear-gradient(to right, rgba(67, 67, 67, 0), rgba(0, 0, 0, 0), rgba(67, 67, 67, 0));
    }
    40% {
      background-image: linear-gradient(to right, rgba(67, 67, 67, 0), rgba(0, 0, 0, 0), rgba(67, 67, 67, 0));
    }
    45% {
      background-image: linear-gradient(to right, rgba(67, 67, 67, 0.5), rgba(0, 0, 0, 0.5), rgba(67, 67, 67, 0.5));
    }
    46% {
      background-image: linear-gradient(to right, rgba(67, 67, 67, 0), rgba(0, 0, 0, 0), rgba(67, 67, 67, 0));
    }
    54% {
      transform: translate(-48%, -50%) rotate(25.82deg) skewX(0deg);
    }
    95.1% {
      transform: translate(-48%, -50%) rotate(25.82deg) skewX(-3deg);
    }
  
    95.2% {
      transform: translate(-48%, -50%) rotate(25.82deg) skewX(-89deg);
    }
  
    95.3% {
      transform: translate(-48%, -50%) rotate(25.82deg) skewX(2deg);
    }
  
    100% {
      transform: translate(-48%, -50%) rotate(25.82deg) skewX(0);
      background-image: linear-gradient(to right, rgba(67, 67, 67, 0.4), rgba(0, 0, 0, 0.4), rgba(67, 67, 67, 0.4));
    }
  }
  
  @keyframes shine {
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    100% {
      opacity: 0;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
    }
  }

  * {
    font-family: 'Poppins', sans-serif;
  }
  body {
    line-height: 1.25;
    height: auto;

    img {
      height: auto;
      max-width: 100%;
    }

    .button-connect {
      button {
        background: linear-gradient(147.14deg, #73DFE7 6.95%, #0063F7 93.05%);
        border-radius: 12px;
      }
    }
  }

  #root {
    background: #ffdde580;
  }

  .ant-modal{
    background: ${({ theme }) => (theme.isDark ? '#0C131F' : 'transparent')};
    border-radius: 15px;

    .ant-modal-content{
      background: ${({ theme }) => (theme.isDark ? '#0C131F' : '#fff')};
      border-radius: 15px;
    }
  }

  button {
    &#swap-button, &#confirm-swap-or-send{
      &:not(:disabled) {
        color: #ffffff;
        height: 45px;
        background: #FDE905;
        border-radius: 12px;
      }
    }
  }

  svg {
    cursor: pointer;
  }

  #wallet-connect-nabox{
    display: none;
  }

  [id^="wallet-connect-"]{
    border: 1px solid #c92dff;
    margin-bottom: 24px;
    background: transparent;

    div{
      color: #fff;
    }
  }

  [id^="wallet-connect-martian"]{
    svg {
      background: #fff;
      border-radius: 50%;
    }
  }

  h2[color="text"] {
    font-family: 'Monument Extended';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FCFCFD;

    @media (min-width: 991px){
      font-size: 21px;
    }
  }

  .ant-modal {
    padding-bottom: 0;
    background: #fff;
    box-shadow: 1px 1px 10px #00000030;

    .ant-modal-content {
      border-radius: 12px;
      background: #fff;

      .ant-modal-body {
        padding: 16px;
      }
    }
  }
`

export default GlobalStyle
