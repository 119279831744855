import { Skeleton } from 'poodlefi-libs-uikit'
import React, { useState } from 'react'
import AccountSwr from 'services/swr/account'
import PackageSwr from 'services/swr/package'
import styled from 'styled-components'
import ModalPackageHistory from 'components/Modal/ModalPackageHistory'
import PackageItem from './PackageItem'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const MyHistory = () => {
  const { data: history, mutate: refreshHistory } = PackageSwr.useGetPackageHistory()
  const { mutate: refreshBalances } = AccountSwr.useGetBalance()

  const [modalHistory, setModalHistory] = useState({
    toggle: false,
    modalData: undefined,
  })

  const handleViewHistory = (list) => setModalHistory({ toggle: true, modalData: list })

  const handleSuccess = async () => Promise.all([refreshBalances(), refreshHistory()])

  if (!history) return <Skeleton width="100%" height={200} />

  return (
    <Wrapper>
      {history?.map((item) => (
        <PackageItem key={item?.id} history={item} onSuccess={handleSuccess} onViewHistory={handleViewHistory} />
      ))}

      <ModalPackageHistory
        visible={modalHistory.toggle}
        onCancel={() => setModalHistory({ toggle: false, modalData: undefined })}
        modalData={modalHistory.modalData}
      />
    </Wrapper>
  )
}

export default MyHistory
