import fetchWrapper from 'helpers/fetch-wrapper'
import useSWR, { SWRConfiguration } from 'swr'
import SWR_KEY from './constant'

const apiBaseUrl = `/package`

const useGetMyPackageList = (configs?: SWRConfiguration) => {
  const fetchData = async () => fetchWrapper.get(`${apiBaseUrl}/search`).then((res) => res?.data?.docs?.sort((a,b) => (a?.min_amount / 1e6) - (b?.min_amount / 1e6)))

  return useSWR(SWR_KEY.GET_PACKAGE_LIST, fetchData, {
    revalidateOnFocus: false,
	...configs
  })
}

const useGetPackageHistory = (configs?: SWRConfiguration) => {
  const fetchData = async () => fetchWrapper.get(`${apiBaseUrl}/wallet`).then((res) => res?.data?.docs)

  return useSWR(SWR_KEY.GET_PACKAGE_HISTORY, fetchData, {
    revalidateOnFocus: false,
	...configs
  })
}

const invest = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`${apiBaseUrl}/create`, body).then(async (data: any) => {
    if ((data?.status === 200 || data?.status === 201)) {
      if (cbs) cbs(data?.data)
      return data
    }
    if (cbe) cbe(data?.data)
    return data
  })
}

const claimReward = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`${apiBaseUrl}/claim-interest`, body).then(async (data: any) => {
    if ((data?.status === 200 || data?.status === 201)) {
      if (cbs) cbs(data?.data)
      return data
    }
    if (cbe) cbe(data?.data)
    return data
  })
}

const PackageSwr = {
	useGetMyPackageList,
  invest,
  useGetPackageHistory,
  claimReward
} 

export default PackageSwr
