import BackButton from 'components/BaseButton/BackButton'
import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Tabs } from 'antd'
import InvestmentSection from 'pages/Home/components/InvestmentSection'
import MyHistory from './MyHistory'

const Wrapper = styled.div`
	.ant-tabs-nav-list {
		width: 100%;

		.ant-tabs-tab {
			flex: 1;
			margin: 0;
			justify-content: center;

			> div {
				color: rgb(102, 102, 102);
			}

			&.ant-tabs-tab-active {
				div {
					color: rgb(254, 110, 162);
				}
			}
		}
	}

	.ant-tabs-ink-bar {
		background: rgb(254, 110, 162);
	}
`

const InvestmentDetail = () => {
	const location = useLocation()
	const checkParams = location?.search?.split("?tab=")[1]

  return (
    <Wrapper>
      <BackButton title="Investment" />

      <Tabs defaultActiveKey={checkParams === 'history' ? "2" : "1"}>
        <Tabs.TabPane tab="All" key="1">
          <InvestmentSection />
        </Tabs.TabPane>
        <Tabs.TabPane tab="History" key="2">
          <MyHistory />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  )
}

export default InvestmentDetail
