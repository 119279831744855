import fetchWrapper from 'helpers/fetch-wrapper'
import { authService } from 'services'
import useSWR, { SWRConfiguration } from 'swr'
import SWR_KEY from './constant'

const apiBaseUrl = `/account`

const useGetBalance = (configs?: SWRConfiguration) => {
  const token = authService.getToken()
  const fetchData = async () => fetchWrapper.get(`${apiBaseUrl}/balance`).then((res) =>  {
    return {
      TRX: res?.data?.TRX / 1e6,
      USDT: res?.data?.USDT / 1e18,
      NEWP: res?.data?.NEWP / 1e4,
      totalWithdrawTransactionsCount: res?.data?.totalWithdrawTransactionsCount
    }
  })

  return useSWR(token ? [SWR_KEY.GET_ACCOUNT_BALANCE, token] : null, fetchData, {
    revalidateOnFocus: false,
	...configs
  })
}

const useGetMyProfile = (configs?: SWRConfiguration) => {
  const token = authService.getToken()
  const fetchData = async () => fetchWrapper.get(`/me`).then((res) => res?.data)

  return useSWR(token ? [SWR_KEY.GET_ME, token] : null, fetchData, {
    revalidateOnFocus: false,
	...configs
  })
}

const withdraw = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`${apiBaseUrl}/withdraw`, body).then(async (data: any) => {
    if ((data?.status === 200 || data?.status === 201)) {
      if (cbs) cbs(data?.data)
      return data
    }
    if (cbe) cbe(data?.data)
    return data
  })
}

const AccountSwr = {
	useGetBalance,
  useGetMyProfile,
  withdraw
} 

export default AccountSwr
